import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/nologin',
		name: 'nologin',
		component: () => import('../views/nologin/index.vue'),
	},
	{
		path: '/personal',
		name: 'personal',
		redirect: '/personal/index',
		component: () => import('../views/common/layout.vue'),
		children: [
			{
				path: 'phone',
				name: 'personal_phone',
				component: () => import('../views/personal/phone.vue'),
			},
			{
				path: 'mailbox',
				name: 'personal_mailbox',
				component: () => import('../views/personal/mailbox.vue'),
			},
			{
				path: 'phone2',
				name: 'personal_phone2',
				component: () => import('../views/personal/phone2.vue'),
			},
			{
				path: 'mailbox2',
				name: 'personal_mailbox2',
				component: () => import('../views/personal/mailbox2.vue'),
			}
		]
	},
	{
		path: '/speedpay',
		name: 'speedpay',
		redirect: '/speedpay/papers',
		component: () => import('../views/common/layout.vue'),
		children: [
			{
				path: 'papers',
				name: 'speedpay_papers',
				component: () => import('../views/speedpay/papers.vue'),
			},
			
			{
				path: 'index',
				name: 'speedpay_index',
				component: () => import('../views/speedpay/index.vue'),
			},
			{
				path: 'apply',
				name: 'speedpay_apply',
				component: () => import('../views/speedpay/apply.vue'),
			},
			{
				path: 'step3',
				name: 'speedpay_step3',
				component: () => import('../views/speedpay/step3.vue'),
			},
			{
				path: 'step4',
				name: 'speedpay_step4',
				component: () => import('../views/speedpay/step4.vue'),
			},
			{
				path: 'step5',
				name: 'speedpay_step5',
				component: () => import('../views/speedpay/step5.vue'),
			},
			{
				path: 'step6',
				name: 'speedpay_step6',
				component: () => import('../views/speedpay/step6.vue'),
			},
			{
				path: 'step7',
				name: 'speedpay_step7',
				component: () => import('../views/speedpay/step7.vue'),
			},
		]
	},
	{
		path: '/withdraw',
		name: 'withdraw',
		redirect: '/withdraw/index',
		component: () => import('../views/common/layout.vue'),
		children: [
			{
				path: 'index',
				name: 'withdraw_index',
				component: () => import('../views/withdraw/index.vue'),
			},
			{
				path: 'amount',
				name: 'withdraw_amount',
				component: () => import('../views/withdraw/amount.vue'),
			},
			{
				path: 'type',
				name: 'withdraw_type',
				component: () => import('../views/withdraw/type.vue'),
			},
			{
				path: 'bank',
				name: 'withdraw_bank',
				component: () => import('../views/withdraw/bank.vue'),
			},
			{
				path: 'history',
				name: 'withdraw_history',
				component: () => import('../views/withdraw/history.vue'),
			},
			{
				path: 'info',
				name: 'withdraw_info',
				component: () => import('../views/withdraw/info.vue'),
			},
			{
				path: 'other',
				name: 'withdraw_other',
				component: () => import('../views/withdraw/other.vue'),
			}
		]
	},
	{
		path: '/rsswithdraw',
		name: 'rsswithdraw',
		redirect: '/rsswithdraw/index',
		component: () => import('../views/common/layout.vue'),
		children: [
			{
				path: 'index',
				name: 'rsswithdraw_index',
				component: () => import('../views/rsswithdraw/index.vue'),
			},
			{
				path: 'amount',
				name: 'rsswithdraw_amount',
				component: () => import('../views/rsswithdraw/amount.vue'),
			},
			{
				path: 'type',
				name: 'rsswithdraw_type',
				component: () => import('../views/rsswithdraw/type.vue'),
			},
			{
				path: 'bank',
				name: 'rsswithdraw_bank',
				component: () => import('../views/rsswithdraw/bank.vue'),
			},
			{
				path: 'history',
				name: 'rsswithdraw_history',
				component: () => import('../views/rsswithdraw/history.vue'),
			},
			{
				path: 'info',
				name: 'rsswithdraw_info',
				component: () => import('../views/rsswithdraw/info.vue'),
			},
			{
				path: 'other',
				name: 'rsswithdraw_other',
				component: () => import('../views/rsswithdraw/other.vue'),
			}
		]
	},
	{
		path: '/deposit',
		name: 'deposit',
		redirect: '/deposit/index',
		component: () => import('../views/common/layout.vue'),
		children: [
			{
				path: 'index',
				name: 'deposit_index',
				component: () => import('../views/deposit/index.vue'),
			},
			{
				path: 'amount',
				name: 'deposit_amount',
				component: () => import('../views/deposit/amount.vue'),
			},
			{
				path: 'history',
				name: 'deposit_history',
				component: () => import('../views/deposit/history.vue'),
			},
			{
				path: 'success',
				name: 'deposit_success',
				component: () => import('../views/deposit/success.vue'),
			},
			{
				path: 'info',
				name: 'deposit_info',
				component: () => import('../views/deposit/info.vue'),
			}
		]
	},
	{
		path: '/rssdeposit',
		name: 'rssdeposit',
		redirect: '/rssdeposit/index',
		component: () => import('../views/common/layout.vue'),
		children: [
			{
				path: 'index',
				name: 'rssdeposit_index',
				component: () => import('../views/rssdeposit/index.vue'),
			},
			{
				path: 'amount',
				name: 'rssdeposit_amount',
				component: () => import('../views/rssdeposit/amount.vue'),
			},
			{
				path: 'history',
				name: 'rssdeposit_history',
				component: () => import('../views/rssdeposit/history.vue'),
			},
			{
				path: 'success',
				name: 'rssdeposit_success',
				component: () => import('../views/rssdeposit/success.vue'),
			},
			{
				path: 'info',
				name: 'rssdeposit_info',
				component: () => import('../views/rssdeposit/info.vue'),
			}
		]
	},
	{
		path: '/des',
		name: 'des',
		redirect: '/des/index',
		component: () => import('../views/common/layout.vue'),
		children: [
			{
				path: 'index',
				name: 'des_index',
				component: () => import('../views/des/index.vue'),
			}
		]
	},
	{
		path: '/account',
		name: 'account',
		redirect: '/account/userinfo',
		component: () => import('../views/common/layout.vue'),
		children: [
			{
				path: 'area',
				name: 'account_area',
				component: () => import('../views/account/area.vue'),
			},
			{
				path: 'userinfo',
				name: 'account_userinfo',
				component: () => import('../views/account/userinfo.vue'),
			},
			{
				path: 'idcard',
				name: 'account_idcard',
				component: () => import('../views/account/idcard.vue'),
			},
			{
				path: 'type',
				name: 'account_type',
				component: () => import('../views/account/type.vue'),
			},
			{
				path: 'bank',
				name: 'account_bank',
				component: () => import('../views/account/bank.vue'),
			},
			{
				path: 'hkbank',
				name: 'account_hkbank',
				component: () => import('../views/account/hkbank.vue'),
			},
			{
				path: 'usdt',
				name: 'account_usdt',
				component: () => import('../views/account/usdt.vue'),
			},
			{
				path: 'success',
				name: 'account_success',
				component: () => import('../views/account/success.vue'),
			},
			{
				path: 'fail',
				name: 'account_fail',
				component: () => import('../views/account/fail.vue'),
			},
		]
	},
	{
		path: '/ipo',
		name: 'ipo',
		redirect: '/ipo/detail',
		component: () => import('../views/common/layout.vue'),
		children: [
			{
				path: 'detail',
				name: 'ipo_detail',
				component: () => import('../views/ipo/detail.vue'),
			},
		]
	},
	{
		path: '/fund',
		name: 'fund',
		redirect: '/fund/detail',
		component: () => import('../views/common/layout.vue'),
		children: [
			{
				path: 'detail',
				name: 'fund_detail',
				component: () => import('../views/fund/detail.vue'),
			},
			{
				path: 'nets',
				name: 'fund_nets',
				component: () => import('../views/fund/nets.vue'),
			},
			{
				path: 'intro',
				name: 'fund_intro',
				component: () => import('../views/fund/intro.vue'),
			},
			{
				path: 'rule',
				name: 'fund_rule',
				component: () => import('../views/fund/rule.vue'),
			},
		]
	},
	{
		path: '/risk',
		name: 'risk',
		redirect: '/risk/index',
		component: () => import('../views/common/layout.vue'),
		children: [
			{
				path: 'index',
				name: 'risk_index',
				component: () => import('../views/risk/index.vue'),
			},
			{
				path: 'exam',
				name: 'risk_exam',
				component: () => import('../views/risk/exam.vue'),
			},
			{
				path: 'level',
				name: 'risk_level',
				component: () => import('../views/risk/level.vue'),
			},
			{
				path: 'info',
				name: 'risk_info',
				component: () => import('../views/risk/info.vue'),
			},
		]
	},
	{
		path: '/info',
		name: 'info',
		redirect: '/info/detail',
		component: () => import('../views/common/layout.vue'),
		children: [
			{
				path: 'detail',
				name: 'info_detail',
				component: () => import('../views/info/detail.vue'),
			},
		]
	},
	{
		path: '/notice',
		name: 'notice',
		redirect: '/notice/index',
		component: () => import('../views/common/layout.vue'),
		children: [
			{
				path: 'index',
				name: 'notice_index',
				component: () => import('../views/notice/index.vue'),
			},
			{
				path: 'info',
				name: 'notice_info',
				component: () => import('../views/notice/info.vue'),
			},
		]
	},
	{
		path: '/help',
		name: 'help',
		redirect: '/help/index',
		component: () => import('../views/common/layout.vue'),
		children: [
			{
				path: 'index',
				name: 'help_index',
				component: () => import('../views/help/index.vue'),
			},
			{
				path: 'info',
				name: 'help_info',
				component: () => import('../views/help/info.vue'),
			},
		]
	},
	{
		path: '/feedback',
		name: 'feedback',
		redirect: '/feedback/index',
		component: () => import('../views/common/layout.vue'),
		children: [
			{
				path: 'index',
				name: 'feedback_index',
				component: () => import('../views/feedback/index.vue'),
			},
			{
				path: 'history',
				name: 'feedback_history',
				component: () => import('../views/feedback/history.vue'),
			},
			{
				path: 'info',
				name: 'feedback_info',
				component: () => import('../views/feedback/info.vue'),
			},
		]
	},
	{
		path: '/invite',
		name: 'invite',
		redirect: '/invite/index',
		component: () => import('../views/common/layout.vue'),
		children: [
			{
				path: 'index',
				name: 'invite_index',
				component: () => import('../views/invite/index.vue'),
			},
			{
				path: 'password',
				name: 'invite_password',
				component: () => import('../views/invite/password.vue'),
			},
			{
				path: 'success',
				name: 'invite_success',
				component: () => import('../views/invite/success.vue'),
			},
			{
				path: 'download',
				name: 'invite_download',
				component: () => import('../views/invite/download.vue'),
			},
		]
	},
	{
		path: '/share',
		name: 'share',
		redirect: '/share/index',
		component: () => import('../views/common/layout.vue'),
		children: [
			{
				path: 'index',
				name: 'share_index',
				component: () => import('../views/share/index.vue'),
			},
		]
	},
	{
		path: '/download',
		name: 'download',
		redirect: '/download/index',
		component: () => import('../views/common/layout.vue'),
		children: [
			{
				path: 'android',
				name: 'download_android',
				component: () => import('../views/download/android.vue'),
			},
		]
	},
]

const router = new VueRouter({
  routes
})

export default router